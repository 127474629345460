// extracted by mini-css-extract-plugin
export var prefooter = "footer-module--prefooter--3-QDW";
export var lpPre = "footer-module--lpPre--1kJDj";
export var prefooterContent = "footer-module--prefooterContent--1LNnJ";
export var heading = "footer-module--heading--23Wnf";
export var content = "footer-module--content--AQBxa";
export var footer = "footer-module--footer--2PQGl";
export var socialContainer = "footer-module--socialContainer--3UyKB";
export var footerContent = "footer-module--footerContent--HNWwT";
export var upperLine = "footer-module--upperLine--19B5L";
export var lowerLine = "footer-module--lowerLine--34IFY";
export var upperLinks = "footer-module--upperLinks--2BNNJ";
export var lp = "footer-module--lp--kLYqj";
export var subheadline = "footer-module--subheadline--3fAlc";