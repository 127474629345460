import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import TagManager from 'react-gtm-module'

import { FACEBOOK_PIXEL_ID, GOOGLE_ANALYTICS_KEY, LINKEDIN_PIXEL_ID, HOTJAR_KEY, LINKEDIN_CONVERSION_ID, GOOGLE_TAG_MANAGER_KEY } from '../../../spectory-config';
import * as styles from "./cookienotice.module.scss"
// import * as btnStyles from "../buttons/buttons.module.scss"

// import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import LinkedInTag from 'react-linkedin-insight';
import { hotjar } from 'react-hotjar';
import Checkbox from '../../../images/svg/icon_checkmark.svg';

import { Link } from '@reach/router';
import Button from '../Button';

const CookieNotice = (props) => {

    const [showForm, setShowForm] = useState(false);
    const [storedCookie, setStoredCookie] = useState(undefined);
    const [form, setForm] = useState(
        {
            analytics: {
                value: false,
                valid: true,
                rules: {}
            },
            marketing: {
                value: false,
                valid: true,
                rules: {}
            },
        }
    );

    useEffect(() => {

        const storedCookie = cookie.load('cookienotice')

        if (storedCookie !== undefined) {
            if (isAllowedAnalytics()) {
                if (GOOGLE_ANALYTICS_KEY !== '') {
                    ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
                    ReactGA.ga('set', 'anonymizeIp', true);
                    ReactGA.pageview(window.location.pathname + window.location.search);
                    // ReactGA.ga('send', 'pageview');
                }

                if (HOTJAR_KEY !== '') {
                    hotjar.initialize(HOTJAR_KEY)
                }


                if (GOOGLE_TAG_MANAGER_KEY !== '') {
                    const tagManagerArgs = {
                        gtmId: GOOGLE_TAG_MANAGER_KEY
                    }

                    TagManager.initialize(tagManagerArgs)
                }
            }

            if (isAllowedMarketing()) {

                if (FACEBOOK_PIXEL_ID !== '') {

                    import('react-facebook-pixel')
                        .then((x) => x.default)
                        .then((ReactPixel) => {
                            ReactPixel.init(FACEBOOK_PIXEL_ID);
                            ReactPixel.pageView();
                        });
                }
                if (LINKEDIN_PIXEL_ID !== '') {
                    LinkedInTag.init(LINKEDIN_PIXEL_ID, 'dc', false);
                    LinkedInTag.track(LINKEDIN_CONVERSION_ID);
                }
            }
        } else {
            setShowForm(true)
        }


    })

    const acceptAllCookies = () => {

        let formCopy = form;

        formCopy.analytics.value = true;
        formCopy.marketing.value = true;

        setForm(prevState => ({
            ...prevState,
            analytics: {
                value: true,
                valid: true,
                rules: {}
            }
        }));

        setCookies();

    }

    const acceptSelectedCookies = () => {
        setCookies();
    }

    const setCookies = () => {

        let acceptedCookies = [];

        Object.keys(form).map(key => {

            if (form[key].value === true) {
                acceptedCookies.push(key);
            }

        });

        let expires = new Date();
        expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 100)

        cookie.save(
            'cookienotice',
            JSON.stringify(acceptedCookies),
            {
                path: '/',
                domain: window.location.hostname,
                expires,
                maxAge: 1000 * 60 * 60 * 24 * 100,
            }
        )

        setStoredCookie(cookie.load('cookienotice'))

        setShowForm(false)

    }

    const isAllowedAnalytics = () => {
        return cookie.load('cookienotice').includes('analytics');
    }

    const isAllowedMarketing = () => {
        return cookie.load('cookienotice').includes('marketing');
    }

    const handleChange = (name, val) => {

        if (val.target !== undefined) {
            val = val.target.value;
        }

        setForm(prevState => ({
            ...prevState,
            [name]: {
                value: val,
                valid: true,
                rules: form[name].rules
            }
        }));
    };



    return (
        showForm ?
            <div className={styles.overlay} >
                <div className={styles.container}>
                    <h2>Cookies</h2>
                    <p className={styles.text}>
                        Wir verwenden Cookies, um die Nutzung auf unserer Website zu verbessern.
                    </p>

                    <input
                        id="nessacaryCheckbox"
                        type="checkbox"
                        checked={true}
                        disabled
                    />
                    <label for="nessacaryCheckbox" style={{ cursor: 'default' }}>
                        <div className={styles.checkboxIconContainer}>
                            <Checkbox alt="Ausgewählt" className={styles.checkboxIcon} />
                        </div>
                        Notwendige
                    </label>
                    <input
                        id="analyticsCheckbox"
                        type="checkbox"
                        checked={form.analytics.value}
                        onChange={() => {
                            handleChange('analytics', !form.analytics.value)
                        }}
                    />
                    <label for="analyticsCheckbox">
                        <div onKeyDown={(event) => {
                            if (event.code === 'Space' || event.code === 'Enter') {
                                event.preventDefault();
                                handleChange('analytics', !form.analytics.value);
                            }
                        }} role='checkbox' tabIndex={0} className={styles.checkboxIconContainer}>
                            <Checkbox className={styles.checkboxIcon} />
                        </div>
                        Analyse zur Weiterentwicklung der Website
                    </label>

                    <input
                        id="marketingCheckbox"
                        type="checkbox"
                        checked={form.marketing.value}
                        onChange={() => {
                            handleChange('marketing', !form.marketing.value)
                        }}
                    />
                    <label for="marketingCheckbox">
                        <div onKeyDown={(event) => {
                            if (event.code === 'Space' || event.code === 'Enter') {
                                event.preventDefault();
                                handleChange('marketing', !form.marketing.value);
                            }
                        }} role='checkbox' tabIndex={0} className={styles.checkboxIconContainer}>
                            <Checkbox className={styles.checkboxIcon} />
                        </div>
                        Aufbereitung und Weiterverfolgung individueller Interessen
                    </label>
                    <div className={styles.cookieNoticeButtonsContainer}>
                        {/* <div onClick={() => acceptAllCookies()} className={`${btnStyles.button}`} >Alle Cookies akzeptieren</div> */}
                        <Button
                            autoFocus={true}
                            text="Alle Cookies akzeptieren"
                            onClick={() => acceptAllCookies()}
                            type={'primary'}
                        />
                        {/* <div onClick={() => acceptSelectedCookies()} className={`${btnStyles.button} ${btnStyles.buttonBorder}`}>Ausgewählte Cookies akzeptieren</div> */}
                        <Button
                            text="Ausgewählte Cookies akzeptieren"
                            onClick={() => acceptSelectedCookies()}
                            type={'secondary'}
                        />
                        <Link to="/datenschutz/">Datenschutz</Link>
                    </div>
                </div>

            </div>
            : null
    )

}

export default CookieNotice