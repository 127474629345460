import React, { useState } from "react";
import { Link } from "gatsby";

import Burger from "../elements/Burger";
import Logo_Desktop from "../../images/Logo/desktop.png";
import Logo_Mobile from "../../images/Logo/mobile.png";

import * as styles from "../../scss/header.module.scss";

const Header = ({ path, mobile, menu, setMenu, hideMenu }) => {
  const [active] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [trustHover, setTrustHover] = useState(false);
  const [companyHover, setCompanyHover] = useState(false);
  const [contactHover, setContactHover] = useState(false);
  const [partnerHover, setPartnerHover] = useState(false);

  const mouseEnter = () => {
    setDropdownOpen(true);
  };

  const mouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <>
      { hideMenu === 'lp' ? 
        <div className="header_banner">
          <span>
            E-Mail-Analyse sichern und Ihren heiligen Gral schützen
          </span>
        </div>
       : ''}
      <div
        className={`container fullwidth white ${styles.sticky} ${styles.container}`}
      >
        <div className={`container`}>
          <Link to="/" className={`${styles.logo}`}>
            <img
              alt="Unternehmenslogo, zurück zu Startseite"
              src={mobile ? Logo_Mobile : Logo_Desktop}
            />
          </Link>
          {mobile ? (
            <>
              <Burger
                active={active}
                state={menu}
                onClick={() => {
                  setMenu(!menu);
                }}
              />
              <div className="sr-only">
                <ul>
                  <li>
                    <Link to="/projekte">Projekte</Link>
                  </li>
                  <li>
                    <Link to="/#icons">Leistungen</Link>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <Link to="/digital-security-for-ceos">
                          <span lang="en">digital security for CEOs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/schutz-digitaler-unternehmenssysteme">
                          Schutz digitaler Unternehmenssysteme
                        </Link>
                      </li>
                      <li>
                        <Link to="/schutz-digitaler-unternehmenskommunikation">
                          Schutz digitaler Unternehmenskommunikation
                        </Link>
                      </li>
                      <li>
                        <Link to="/digital-security-for-it-iot-ot">
                          <span lang="en">
                            digital security for IT - IoT - OT
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/email-signatur-und-verschluesselung">
                          E-Mail Signatur und Verschlüsselung
                        </Link>
                      </li>
                      <li>
                        <Link to="/iot-security-risk-assessment">
                          <span lang="en">IOT security risk assessment</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/associated-digital-security-services">
                          <span lang="en">
                            associated digital security services
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/unternehmen">Unternehmen</Link>
                  </li>
                  <li>
                    <Link to="/netzwerk">Partner</Link>
                  </li>
                  <li>
                    <Link to="/kontakt">Kontakt</Link>
                  </li>
                </ul>
              </div>
            </>
          ) : hideMenu === "simple" || hideMenu === "lp" ? (
            ""
          ) : (
            <div className={"col7"}>
              <div className="sr-only">
                <ul>
                  <li>
                    <Link tabIndex={-1} to="/projekte">
                      Projekte
                    </Link>
                  </li>
                  <li>
                    <Link tabIndex={-1} to="/#icons">
                      Leistungen
                    </Link>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <Link tabIndex={-1} to="/digital-security-for-ceos">
                          <span lang="en">digital security for CEOs</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          tabIndex={-1}
                          to="/schutz-digitaler-unternehmenssysteme"
                        >
                          Schutz digitaler Unternehmenssysteme
                        </Link>
                      </li>
                      <li>
                        <Link
                          tabIndex={-1}
                          to="/schutz-digitaler-unternehmenskommunikation"
                        >
                          Schutz digitaler Unternehmenskommunikation
                        </Link>
                      </li>
                      <li>
                        <Link
                          tabIndex={-1}
                          to="/digital-security-for-it-iot-ot"
                        >
                          <span lang="en">
                            digital security for IT - IoT - OT
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          tabIndex={-1}
                          to="/email-signatur-und-verschluesselung"
                        >
                          E-Mail Signatur und Verschlüsselung
                        </Link>
                      </li>
                      <li>
                        <Link tabIndex={-1} to="/iot-security-risk-assessment">
                          <span lang="en">IOT security risk assessment</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          tabIndex={-1}
                          to="/associated-digital-security-services"
                        >
                          <span lang="en">
                            associated digital security services
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link tabIndex={-1} to="/unternehmen">
                      Unternehmen
                    </Link>
                  </li>
                  <li>
                    <Link tabIndex={-1} to="/netzwerk">
                      Partner
                    </Link>
                  </li>
                  <li>
                    <Link tabIndex={-1} to="/kontakt">
                      Kontakt
                    </Link>
                  </li>
                </ul>
              </div>
              <div aria-hidden="true" className={`${styles.linkContainer}`}>
                <Link
                  onMouseEnter={() => setTrustHover(true)}
                  onMouseLeave={() => setTrustHover(false)}
                  to="/projekte"
                  className={path === "/projekte/" && styles.active}
                >
                  {trustHover ? `Projekte` : "your trust"}
                </Link>
                <div
                  onMouseOver={() => mouseEnter()}
                  onMouseLeave={() => mouseLeave()}
                  style={{ position: "relative" }}
                >
                  <Link
                    style={{ paddingRight: dropdownOpen ? 0 : 5 }}
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                    to="/#icons"
                    className={`${
                      path === "/" && styles.active
                    } dropdownParent`}
                  >
                    {dropdownOpen ? "Leistungen" : "we deliver"}
                  </Link>
                  <div
                    className={`${styles.dropdown} ${
                      dropdownOpen ? styles.visible : ""
                    }`}
                  >
                    <Link to="/digital-security-for-ceos">
                      <span lang="en">digital security for CEOs</span>
                    </Link>
                    <Link to="/schutz-digitaler-unternehmenssysteme">
                      Schutz digitaler Unternehmenssysteme
                    </Link>
                    <Link to="/schutz-digitaler-unternehmenskommunikation">
                      Schutz digitaler Unternehmenskommunikation
                    </Link>
                    <Link to="/digital-security-for-it-iot-ot">
                      <span lang="en">digital security for IT - IoT - OT</span>
                    </Link>
                    <Link to="/email-signatur-und-verschluesselung">
                      E-Mail Signatur und Verschlüsselung
                    </Link>
                    <Link to="/iot-security-risk-assessment">
                      <span lang="en">IOT security risk assessment</span>
                    </Link>
                    <Link to="/associated-digital-security-services">
                      <span lang="en">
                        associated digital security services
                      </span>
                    </Link>
                  </div>
                </div>
                <Link
                  to="/unternehmen"
                  className={path === "/unternehmen/" && styles.active}
                  onMouseEnter={() => setCompanyHover(true)}
                  onMouseLeave={() => setCompanyHover(false)}
                >
                  {companyHover ? `Unternehmen` : "company"}
                </Link>
                <Link
                  to="/netzwerk"
                  className={path === "/netzwerk/" && styles.active}
                  onMouseEnter={() => setPartnerHover(true)}
                  onMouseLeave={() => setPartnerHover(false)}
                >
                  {partnerHover ? "Partner" : "partner"}
                </Link>
                <Link
                  to="/kontakt"
                  className={path === "/kontakt/" && styles.active}
                  onMouseEnter={() => setContactHover(true)}
                  onMouseLeave={() => setContactHover(false)}
                >
                  {contactHover ? `Kontakt` : "contact"}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
