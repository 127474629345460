import * as React from "react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Header from "../components/sections/header";
import Footer from "../components/sections/footer";
import MobileMenu from "./sections/MobileMenu";
import { clearPointData } from "../state/app";
import CookieNotice from "./elements/CookieNotice";
import { Helmet } from "react-helmet";

import Seo from "./sections/seo";

import "../scss/layout.scss";
import "../scss/animations.scss";
import "animate.css/animate.css";
import "../scss/common.scss";
import windowSize from "../func/windowSize";

const Layout = ({ children, path, contentObjects, title, hideMenu }) => {
  const [menu, setMenu] = useState(false);
  const [mobile, setMobile] = useState(false);

  const theMobile = () => {
    if (windowSize() <= 992) {
      setMobile(true);
    } else {
      setMobile(false);
      setMenu(false);
    }
  };

  const dispatch = useDispatch();
  dispatch(clearPointData());

  useEffect(() => {
    theMobile();
    window.addEventListener("resize", theMobile);
    return () => {
      window.removeEventListener("resize", theMobile);
    };
  }, []);

  return (
    <>
      <Helmet>
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Seo title={title} />
      {menu && (
        <MobileMenu
          menu={menu}
          setMenu={setMenu}
          path={path}
          isMobile={mobile}
        />
      )}
      <Header path={path} hideMenu={hideMenu} mobile={mobile} menu={menu} setMenu={setMenu} />
      <CookieNotice />
      <main>{children}</main>
      <Footer type={hideMenu} path={path} />
    </>
  );
};

export default Layout;
