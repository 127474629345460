/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 * 
 * Gebraucht wird react-source-graphql
 * 
 */

import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import favicon180 from "../../images/Favicon.png";

function Seo({ lang, meta, title }) {
    const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
            description
            title
        }
      }
    }
  `);

    const metaDescription = data.site.siteMetadata?.description;
    const defaultTitle = data.site.siteMetadata?.title;
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title || defaultTitle}
            titleTemplate={`%s | ${defaultTitle} ${metaDescription !== "" && metaDescription !== null ? `- ${metaDescription}` : ""
                }`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
            ].concat(meta)}
        />
    );
}

Seo.defaultProps = {
    lang: `de`,
    meta: [],
    description: ``,
};

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default Seo;

/*


  import * as React from "react"
  import PropTypes from "prop-types"
  import { Helmet } from "react-helmet"
  import { useStaticQuery, graphql } from "gatsby"
  import favicon180 from '../../images/Favicon.png'

  function Seo({ lang, meta, title }) {
     const { site } = useStaticQuery(graphql`
     {
       wpWebsiteGlobal {
         pageDescription
         pageTitle
       }
     }
   `)

      const metaDescription = site.wpWebsiteGlobal?.pageTitle;
      const defaultTitle = site.wpWebsiteGlobal?.pageTitle;
      return (
          <Helmet
              htmlAttributes={{
                  lang,
              }}
              title={title || defaultTitle}
              titleTemplate={`%s | ${defaultTitle} ${metaDescription !== "" ? `- ${metaDescription}` : ""}`}
              meta={[
                  {
                      name: `description`,
                      content: metaDescription,
                  },
                  {
                      property: `og:title`,
                      content: title,
                  },
                  {
                      property: `og:description`,
                      content: metaDescription,
                  },
                  {
                      property: `og:type`,
                      content: `website`,
                  },
                  {
                      name: `twitter:card`,
                      content: `summary`,
                  },
                  {
                      name: `twitter:creator`,
                      content: site.siteMetadata?.author || ``,
                  },
                  {
                      name: `twitter:title`,
                      content: title,
                  },
                  {
                      name: `twitter:description`,
                      content: metaDescription,
                  },
              ].concat(meta)}
              link={[
                  { rel: 'icon', sizes: "180x180", type: 'image/png', href: `${favicon180}` },
                  { rel: 'apple-touch-icon', type: 'image/png', href: `${favicon180}` },
                  { rel: 'apple-touch-icon-precomposed', type: 'image/png', href: `${favicon180}` },

              ]}
          />
      )
  }

  Seo.defaultProps = {
      lang: `de`,
      meta: [],
      description: ``,
  }

  Seo.propTypes = {
      description: PropTypes.string,
      lang: PropTypes.string,
      meta: PropTypes.arrayOf(PropTypes.object),
      title: PropTypes.string.isRequired,
  }

  export default Seo
  */
