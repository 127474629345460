import React from "react";
import * as styles from "./styles.module.scss";
const Burger = ({ state, onClick, active }) => {
  return (
    <div className={styles.burgerContainer} aria-hidden="true">
      <div
        onClick={onClick}
        className={`${styles.Burger} ${state ? styles.clicked : ""} ${
          active ? styles.active : styles.inactive
        }`}
      >
        <div className={`${styles.line} ${styles.shorterLine}`}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>
    </div>
  );
};

export default Burger;
