import React from "react";
import { Link } from "gatsby";
import * as styles from "../../scss/footer.module.scss";
import Button from "../elements/Button";
import SpectoryLogoAnimated from "../elements/SpectoryLogoAnimated";
import LPButton from "../elements/LPButton";

const Footer = (props) => {
  console.log(props.type);

  if (props.type === "lp") {
    return (
      <div className={`${styles.footerWrapper} ${styles.lp}`}>
        <div className={`${styles.prefooter} ${styles.lpPre} container fullwidth`}>
          <div className={`container center_text ${styles.prefooterContent}`}>
            <div className="col3" />
            <div className={`col6`}>
              <div className={styles.content}>
                <span className={styles.subheadline}>
                  Kostenlose E-Mail-Analyse
                </span>
                <h2 className={styles.heading}>
                  Wie sicher ist Ihre E‑Mail-Kommunikation?
                </h2>
                <LPButton
                  type="email"
                  blue={true}
                  text="E-Mail zur Analyse senden"
                />
              </div>
            </div>
            <div className="col3" />
          </div>
        </div>

        <div className={`${styles.footer} container fullwidth`}>
          <div className={`${styles.footerContent} container`}>
            <div className={styles.upperLine}>
              <div className={`${styles.upperLinks} col5`}>
                <Link to="/kontakt">Kontakt</Link>
                <Link to="/datenschutz">Datenschutz</Link>
                <Link to="/impressum">Impressum</Link>
              </div>
            </div>
            <div className={styles.lowerLine}>
              <p>
                © Copyright 2022 go4digital.
                <br />
                <br />
                <SpectoryLogoAnimated />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.footerWrapper}>
        <div className={`${styles.prefooter} container fullwidth`}>
          <div className={`container ${styles.prefooterContent}`}>
            <div className={`col5`}>
              <div className={styles.content}>
                <h2 className={styles.heading}>
                  Mehr über unser Unternehmen erfahren
                </h2>
                <Button
                  type="primary"
                  aria_label="Erfahren Sie mehr über unser Unternehmen"
                  destination={props.path === "/" ? "/unternehmen" : "/#icons"}
                  text="Erfahren Sie mehr"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.footer} container fullwidth`}>
          <div className={`${styles.footerContent} container`}>
            <div className={styles.upperLine}>
              <div className={`${styles.upperLinks} col5`}>
                <Link to="/kontakt">Kontakt</Link>
                <Link to="/datenschutz">Datenschutz</Link>
                <Link to="/impressum">Impressum</Link>
              </div>
            </div>
            <div className={styles.lowerLine}>
              <p>
                © Copyright 2022 go4digital.
                <br />
                <br />
                <SpectoryLogoAnimated />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
