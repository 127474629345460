import React from "react";
import * as styles from "./styles.module.scss";
import ArrowWhite from "../../../images/ArrowRight_white.png";
import ArrowBlue from "../../../images/ArrowRight_Blue.png";

const LPButton = (props) => {
  const isBlue = props.blue === true;
  return props.type === "email" ? (
    <div className={styles.wrapper}>
      <a
        className={`${styles.button} ${isBlue ? styles.filled : ""}`}
        href="mailto:office@go4digital.cc?subject=Kostenlose E-Mail Analyse&body=Sehr geehrter Herr Sonnleitner,%0D%0A
hiermit bitte ich Sie, die für mich kostenlose E-Mail-Security-Analyse jener E-Mail-Domain, mit der ich diese E-Mail versende, durchzuführen.%0D%0A
Mit freundlichen Grüßen,%0D%0A%0D%0A
Achtung: Um eine die Analyse Ihrer betrieblichen E-Mail-Sicherheit zu ermöglichen, muss diese E-Mail über ihre berufliche E-Mail-Adresse an uns gesendet werden."
      >
        {props.text}
        <img src={ArrowWhite} alt="" />
      </a>
    </div>
  ) : props.type === "pdf" ? (
    <div className={styles.wrapper}>
      <a href="https://go4digital.cc/wp/wp-content/uploads/2024/05/Solution_Brief.pdf" className={`${styles.button} ${styles.dark}`} target="_blank" download="Solution_Brief.pdf">
        {props.text}
        <img src={ArrowBlue} alt="" />
      </a>
    </div>
  ) : (
    <div className={styles.wrapper}>
      <a className={`${styles.button} ${styles.dark}`} href="#">
        {props.text}
        <img src={ArrowBlue} alt="" />
      </a>
    </div>
  );
};

export default LPButton;
