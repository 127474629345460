import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
import * as styles from "./styles.module.scss";
import Social from "../../elements/Social";

const MobileMenu = ({ menu, setMenu, path }) => {
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      ref.current.classList.add(styles.active);
    }, 100);
  }, []);
  return (
    <>
      <div className={styles.overlay}></div>
      <div ref={ref} className={styles.menu}>
        <div className={`container ${styles.itemsContainer}`}>
          <ul className={`col6 centered ${styles.items}`}>
            <li>
              <Link
                className={`${styles.link} ${path === "/projekte/" && styles.active}`}
                onClick={() => {
                  setMenu(!menu);
                }}
                to={"/projekte"}
              >
                Your Trust
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.link} ${path === "/" && styles.active}`}
                onClick={() => {
                  setMenu(!menu);
                }}
                to={"/#icons"}
              >
                We Deliver
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.link} ${path === "/unternehmen/" && styles.active}`}
                onClick={() => {
                  setMenu(!menu);
                }}
                to={"/unternehmen"}
              >
                Company
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.link} ${path === "/netzwerk/" && styles.active}`}
                onClick={() => {
                  setMenu(!menu);
                }}
                to={"/netzwerk"}
              >
                Partner
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.link} ${path === "/kontakt/" && styles.active}`}
                onClick={() => {
                  setMenu(!menu);
                }}
                to={"/kontakt"}
              >
                Contact
              </Link>
            </li>
          </ul>

        </div>
      </div>
    </>
  );
};

export default MobileMenu;
